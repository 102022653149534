.container {
    /* background-color: #222; */
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    flex-direction: row-reverse;
  }

  .daftar {
    color: red !important;
    margin-left: 5px;
  }
  
  .daftar:hover {
    cursor: pointer;
  }
  
  .wrapp-form {
    padding: 20px 100px;
    width: 100%;
    height: 550px;
    border: 5px solid white;
    box-shadow: 0 5px 10px rgb(255, 255, 255);
    color: #000;
  }
  
  .wrapp-fields {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    margin-top: 25px;
  }
  
  .css-hjtp1-MuiInputBase-root-MuiOutlinedInput-root {
    color: #FFF !important;
  }
  
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    color: #FFF !important;
  }

  /* .wrappField {
    margin-bottom: 20px;
  } */