.containerContent {
    padding: 20px
}

.wrappField {
    margin-bottom: 20px !important;
}

.wrappFieldSistolik {
    margin-right: 15px !important;
}

.wrappFieldAnamnesis {
    margin-top: 62px !important;
    margin-bottom: 20px !important;
}